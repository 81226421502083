.container {
    background-color: var(--nav-color);
    width: 100%;
    height: 100%;
    z-index: 100;
    position: fixed;
    right: 0px;
    padding: 3rem 0rem 0rem 0rem;
}

.buttonContainer {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 35px;
    padding-left: .7rem;
}

.button {
    color: white;
    font-family: text;
    font-size: 1.5rem;
    opacity: 80%;
    letter-spacing: .13rem;
    cursor: pointer;
    padding-bottom: 3px;
    text-transform: uppercase;
    font-weight: bold;
}

.button:hover {
    opacity: 50%;
}

.button_active {
    color: white;
    font-family: text;
    font-size: 1.5rem;
    opacity: 80%;
    letter-spacing: .13rem;
    cursor: pointer;
    border-bottom: 2px white solid;
    padding-bottom: 1px;
    text-transform: uppercase;
    font-weight: bold;
}


/*QUERIES*/

@media (width>768px) {
    .container {
        display: none;
    }
}