@font-face {
  font-family: fun;
  src: url(/src/Assets/Fonts/Chalkiez-Regular.otf);
}

@font-face {
  font-family: title;
  src: url(/src//Assets//Fonts/BebasKai.otf);
}

@font-face {
  font-family: text;
  src: url(/src/Assets/Fonts/CreatoDisplay-Regular.otf);
}

:root {
  --nav-color: #201e1f;
  --primary-color: rgb(25, 75, 147);
  --secondary-color: rgb(21, 224, 163);
  --primary-title-size: 3.5rem;
  --primary-title-color: rgb(54, 54, 54);
  --secondary-title-color: rgb(255, 123, 14);
  --primary-text-size: 1.1rem;
  --primary-text-color: rgb(104, 104, 104);
  --secondary-text-size: 1.4rem;
}

a {
  text-decoration: none;
}

strong {
  color: var(--primary-title-color);
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}