.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5rem;
}

.articleContainer {
    width: 65%;
    animation: appear .5s ease-in-out 0s 1 forwards;
}

.topImageContainer {
    height: 30vh;
    overflow: hidden;
    margin-bottom: 2rem;
    animation: appear .5s ease-in-out 0s 1 forwards;
}

.topImage {
    width: 100%;
    object-fit: cover;
}

.title {
    cursor: default;
    font-family: title;
    color: var(--primary-color);
    font-weight: bold;
    font-size: var(--primary-title-size);
    transform: translate(0%, 0%);
    transition: all .2s ease-in-out;
    animation: slideLeft 1s ease-in-out 0s 1;
}

.title:hover {
    transform: translate(3%, 0%);
    transition: all .2s ease-in-out;
}

.subtitle {
    margin-top: 2rem;
    font-family: title;
    color: white;
    font-size: 2rem;
    background-color: var(--primary-color);
    padding: 1rem;
    border-radius: 25px;
}

.list {
    color: white;
    width: fit-content;
    font-family: text;
    text-align: left;
    font-size: var(--primary-text-size);
    padding: 0;
}

.list li{
    padding: 1rem;
    background-color: var(--primary-color);
    border-radius: 25px;
}

.text {
    font-family: text;
    font-size: var(--primary-text-size);
    color: var(--primary-text-color);
    text-align: justify;
    margin-bottom: 1rem;
}

.text_strong {
    font-family: text;
    font-weight: bolder;
    font-style: italic;
    font-size: var(--secondary-text-size);
    color: var(--primary-color);
    text-align: center;
    margin: 1.8rem 0 1.8rem 0;
}

.image {
    width: 40%;
    object-fit: cover;
}

.box {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.logo{
    width: 80%;
}

@keyframes appear {
    0% {
        opacity: 0%;
    }

    100% {
        opacity: 100%;
    }
}

@keyframes slideLeft {
    0% {
        transform: translate(-300%, 0%);
    }

    100% {
        transform: translate(0%, 0%);
    }
}

/*QUERIES*/

@media (width<=768px) {
    .articleContainer {
        width: 90%;
    }
    .topImage {
        width: 100%;
        height: 100%;
    }
    .box{
        flex-direction: column;
    }
    .image{
        width: 100%;
    }
}