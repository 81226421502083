.container {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    top: 80px;
    right: 30px;
    border-radius: 20px;
    width: 17%;
    animation: slide-in-left .5s ease-in-out .1s 1 forwards;
    transform: translate(200%, 0%);
}

@keyframes slide-in-left {
    0% {
        transform: translate(200%, 0%);
    }

    100% {
        transform: translate(0%, 0%);
    }
}

/*QUERIES*/

@media (width<=1170px) {
    .container {
        display: none;
    }
}