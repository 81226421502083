.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: .5rem;
    width: 50%;
    margin-top: 3rem;
}

.buttonActive {
    background-color: var(--secondary-title-color);
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    font-family: text;
    letter-spacing: .15rem;
    font-size: .8rem;
    padding: 1rem;
    cursor: pointer;
    border-radius: 10px;
    transition: all .2s ease-in-out;
    width: 40%;
}

.buttonActive:hover {
    scale: 1.05;
}

.buttonInactive {
    background-color: var(--primary-text-color);
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    font-family: text;
    letter-spacing: .15rem;
    padding: 1rem;
    border-radius: 10px;
    font-size: .8rem;
    width: 40%;
}

@media (width<=768px) {
    .buttonActive {
        width: 100%;
    }

    .buttonInactive {
        width: 100%;
    }
}