.container{
    margin:0;
    background-color: var(--nav-color);
}

.buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    height: 100px;
    color: white;
    font-size: 3rem;
}

.icon {
    color: white;
    opacity: 60%;
    transition: all .2s ease-in-out;
    cursor: pointer;
}

.icon:hover {
    opacity: 100%;
}

.bot{
    width: 100%;
}