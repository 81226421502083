.container {
    border-radius: 20px;
    width: 100%;
    box-shadow: 1px 1px 7px rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    padding: .1rem 1rem .1rem 0;
}

.container a {
    color: var(--primary-text-color);
}

.container ul {
    padding: 0 0 0 20px;
    color: var(--primary-text-color);
    width: fit-content;
    font-family: text;
    font-weight: bold;
    text-align: left;
    font-size: var(--primary-text-size);
}

.container li {
    font-size: .8rem;
    list-style-type: none;
    margin-bottom: .7rem;
    cursor: pointer;
    transition: all .1s ease-in-out;
}

.container li:hover {
    color: var(--primary-title-color);
}