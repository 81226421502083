.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 5rem;
}

.articleContainer {
    width: 55%;
    animation: appear 1s ease-in-out 0s 1 forwards;
}

.topImageContainer {
    height: 30vh;
    overflow: hidden;
    margin-bottom: 2rem;
}

.topImage {
    width: 100%;
    object-fit: cover;
    object-position: top;
}

.title {
    padding-top: 4rem;
    margin-bottom: 1rem;
    font-family: title;
    color: var(--primary-color);
    font-weight: bold;
    font-size: var(--primary-title-size);
    transition: all .2s ease-in-out;
    animation: slide-in-top .3s ease-in-out 0s 1 forwards;
}
.title_2 {
    margin-bottom: 1rem;
    font-family: title;
    color: var(--primary-color);
    font-weight: bold;
    font-size: var(--primary-title-size);
    transition: all .2s ease-in-out;
    animation: slide-in-top .3s ease-in-out 0s 1 forwards;
}


.subtitle {
    padding-top: 4rem;
    font-family: title;
    color: var(--primary-title-color);
    font-size: 2rem;
    margin-bottom: 1rem;
}

.subtitle_2 {
    padding-top: 1rem;
    font-family: title;
    color: var(--primary-title-color);
    font-size: 2rem;
    margin-bottom: 1rem;
}

.list {
    color: var(--primary-text-color);
    width: fit-content;
    font-family: text;
    text-align: left;
    font-size: var(--primary-text-size);
}

.list li {
    margin-bottom: .4rem;
}

.text {
    font-family: text;
    font-size: var(--primary-text-size);
    color: var(--primary-text-color);
    text-align: justify;
    margin-bottom: 1rem;
}

.text_strong {
    font-family: text;
    font-weight: bolder;
    font-size: var(--secondary-text-size);
    font-style: italic;
    color: var(--primary-color);
    text-align: center;
    margin: 1.8rem 0 1.8rem 0;
}

.image {
    width: 100%;
    object-fit: cover;
}

.nmplmImage{
    width: 100%;
    object-fit: cover;
    animation: slide-in-top .3s ease-in-out 0s 1 forwards;
}

.nmplm_title_2 {
    margin-bottom: 1rem;
    font-family: title;
    color: var(--secondary-title-color);
    font-weight: bold;
    font-size: var(--primary-title-size);
    transition: all .2s ease-in-out;
    animation: slide-in-top .3s ease-in-out 0s 1 forwards;
}

.nmplm_text_strong {
    font-family: text;
    font-weight: bolder;
    font-size: var(--secondary-text-size);
    font-style: italic;
    color: var(--primary-title-color);
    text-align: center;
    margin: 1.8rem 0 1.8rem 0;
}

.box {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

@keyframes slide-in-top {
    0% {
        transform: translate(0%, -100%);
    }

    100% {
        transform: translate(0%, 0%);

    }
}

@keyframes appear {
    0% {
        opacity: 0%;
    }

    100% {
        opacity: 100%;
    }
}

/*QUERIES*/

@media (width<=768px) {
    .articleContainer {
        width: 85%;
        animation: appear 1s ease-in-out 0s 1 forwards;
    }
    .topImageContainer {
        margin-bottom: 0rem;
    }
    .topImage {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
    }
    .title {
        padding-top: 1rem;
    }
}