.mainImageContainer {
    height: 100vh;
    overflow: hidden;
    animation: appear 1.5s ease-in-out 0s 1;
}

.logo {
    width: 40vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: drop-shadow(4px 4px 3px rgb(0, 0, 0));
    z-index: 99;
    animation: slideLeft 1.5s ease-in-out;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.logo:hover {
    width: 43vw;
}

.mainImage {
    width: 100%;
    object-fit: cover;
    animation-name: breathe;
    animation-duration: 25s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    filter: blur(4px);
}

.frase {
    opacity: 75%;
    width: 60%;
}

@keyframes breathe {
    0% {
        transform: scale(1.0);
    }

    50% {
        transform: scale(1.1);
    }

    100% {
        transform: scale(1.0);
    }
}

@keyframes appear {
    0% {
        opacity: 0%;
    }

    100% {
        opacity: 100%;
    }
}

@keyframes slideLeft {
    0% {
        transform: translate(-300%, -50%);
    }

    100% {
        transform: translate(-50%, -50%);
    }
}

@keyframes slideRight {
    0% {
        transform: translate(300%, 20%);
    }

    100% {
        transform: translate(-50%, 20%);
    }
}

/*QUERIES*/

@media (width<=1170px) {
    .mainImage {
        width: 100%;
        height: 100%;
    }
}

@media (width<=768px) {
    .logo {
        width: 80vw;
    }
}