.dpaTitle {
    color: var(--primary-color);
    font-family: title;
    font-size: 3rem;
    margin-bottom: 2rem;
    filter: drop-shadow(white 0px 0px 5px);
    text-decoration: underline;
}

.dpa_titleImage{
    width: 100%;
}

.dpaContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: .5rem;
    width: 100%;
}

.dpaImage {
    position: absolute;
    width: 100%;
    z-index: -1;
}

.dpaButtonContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: .5rem;
    width: 100%;
}

.dpaButton {
    background-color: var(--primary-color);
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    font-family: text;
    letter-spacing: .15rem;
    padding: 1rem;
    cursor: pointer;
    border-radius: 10px;
    transition: all .2s ease-in-out;
    width: 40%;
}

.dpaButton:hover {
    scale: 1.02;
}

@media (width<=1170px) {
   
}

@media (width<=768px) {
    .dpaButtonContainer {
        width: 90%;
    }
    .dpaButton {
        font-size: 0.65rem;
    }
}