.container {
    background-color: var(--nav-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    animation: appear 1s;
    padding: 0 60px 0 60px;
    position: sticky;
    top: 0px;
    z-index: 100;
    border-bottom: 1px white solid;
}

.ncContainer {
    display: flex;
}

.logo {
    height: 50px;
    cursor: pointer;
    margin-right: 20px;
}

.buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2vw;
    text-transform: uppercase;
}

.button {
    color: white;
    font-family: text;
    font-size: .58rem;
    opacity: 80%;
    letter-spacing: .13rem;
    cursor: pointer;
    padding-bottom: 3px;
}

.button:hover {
    opacity: 50%;
}

.button_active {
    color: white;
    font-family: text;
    font-size: .58rem;
    opacity: 80%;
    letter-spacing: .13rem;
    cursor: pointer;
    border-bottom: 2px white solid;
    padding-bottom: 1px;
}

@keyframes appear {
    0% {
        transform: translate(0%, -100%);
    }

    100% {
        transform: translate(0%, 0%);
    }
}

/*QUERIES*/

@media (width<=1170px) {
    .button {
        font-size: .5rem;
    }

    .button_active {
        font-size: .5rem;
    }
}

@media (width<=768px) {
    .container {
        display: none;
    }
}