.container {
    background-color: var(--nav-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    padding: 0 20px 0 20px;
    position: sticky;
    top: 0px;
    z-index: 100;
    border-bottom: 1px white solid;
}

.ncContainer {
    display: flex;
}

.logo {
    height: 50px;
    cursor: pointer;
    margin-right: 20px;
}

.buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2vw;
}

.button {
    color: white;
    font-family: text;
    font-size: .8rem;
    opacity: 80%;
    letter-spacing: .13rem;
    cursor: pointer;
    padding-bottom: 3px;
}

.button:hover {
    opacity: 50%;
}

.button_active {
    color: white;
    font-family: text;
    font-size: .80rem;
    opacity: 80%;
    letter-spacing: .13rem;
    cursor: pointer;
    border-bottom: 2px white solid;
    padding-bottom: 1px;
}

.menuIcon{
    color: white;
    font-size: 2rem;
    cursor: pointer;
}

/*QUERIES*/

@media (width>768px) {
    .container {
        display: none;
    }
}