.sections {
    display: flex;
    justify-content: center;
    overflow: hidden;
}

/**************************************************************/
.mainSectionContainer {
    overflow: hidden;
    width: 100%;
    height: 300px;
    position: relative;
    margin: 4px;
}

.mainSection {
    height: 375px;
    width: 100%;
    object-fit: cover;
    transition: 0.2s ease-in-out;
    scale: 1.01;
}

.mainSectionTitle {
    letter-spacing: 1px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    color: white;
    font-weight: bold;
    font-size: 3rem;
    font-family: title;
    transform: translate(-50%, 150%);
    cursor: pointer;
    pointer-events: none;
    transition: 0.2s ease-in-out;
    text-shadow: 2px 2px 5px rgb(0, 0, 0);
}

.mainSectionContainer:hover {
    .mainSection {
        cursor: pointer;
        scale: 1.21;
        filter: blur(4px);
    }

    .mainSectionTitle {
        font-size: 4rem;
        transform: translate(-50%, -50%);
    }
}

.mainSctionContainer img {
    object-position: 60%;
}

/**************************************************************/

.sectionContainer {
    overflow: hidden;
    width: 100%;
    height: 300px;
    position: relative;
    margin: 4px;
}

.sectionContainer img {
    object-position: 60%;
}

.sectionContainer:hover {
    .section {
        cursor: pointer;
        scale: 1.21;
        filter: blur(4px);
    }

    .sectionTitle {
        font-size: 40px;
        transform: translate(-50%, -50%);
    }
}

.sectionTitle {
    letter-spacing: 1px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    color: white;
    font-weight: bold;
    font-size: 30px;
    font-family: title;
    transform: translate(-50%, 300%);
    cursor: pointer;
    pointer-events: none;
    transition: 0.2s ease-in-out;
    text-shadow: 2px 2px 5px rgb(0, 0, 0);
}

.section {
    height: 375px;
    width: 100%;
    object-fit: cover;
    transition: 0.2s ease-in-out;
    scale: 1.01;
}

/*QUERIES*/

@media (width<=768px) {
    .sections {
        flex-direction: column;
    }

    .sectionContainer {
        margin: 0px;
    }
    .mainSectionContainer {
        margin: 0px;
    }
    .mainSectionTitle {
        font-size: 30px;
        transform: translate(-50%, 300%);
    }
    .mainSectionContainer:hover {
        .mainSectionTitle {
            font-size: 40px;
            transform: translate(-50%, -50%);
        }
    }
}